import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Viridi
			</title>
			<meta name={"description"} content={"Cultivate peace and relaxation as you tend to your virtual succulent garden in Viridi."} />
			<meta property={"og:title"} content={"Viridi"} />
			<meta property={"og:description"} content={"Cultivate peace and relaxation as you tend to your virtual succulent garden in Viridi."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-6-removebg-preview.png?v=2024-11-05T13:40:06.191Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-6-removebg-preview.png?v=2024-11-05T13:40:06.191Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-6-removebg-preview.png?v=2024-11-05T13:40:06.191Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-6-removebg-preview.png?v=2024-11-05T13:40:06.191Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-6-removebg-preview.png?v=2024-11-05T13:40:06.191Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-6-removebg-preview.png?v=2024-11-05T13:40:06.191Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-6-removebg-preview.png?v=2024-11-05T13:40:06.191Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="text" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
		</Components.Header>
		<Section padding="180px 0 180px 0" background="linear-gradient(0deg,rgba(116, 12, 54, 0.5) 0%,rgba(116, 12, 54, 0.56) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-5.jpg?v=2024-11-05T13:39:59.888Z) 50% 40% /cover repeat scroll padding-box" md-padding="100px 0 100px 0" quarkly-title="Content-12">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				justify-content="center"
				md-flex-direction="column"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 80px 0px 0px"
				width="100%"
				lg-width="70%"
				md-width="100%"
				md-padding="0px 0 0px 0px"
				md-margin="0px 0px 50px 0px"
				text-align="center"
			>
				<Text margin="0px 0px 10px 0px" font="normal 500 20px/1.5 --fontFamily-sans" color="white">
					Experience
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline1" color="white">
					Viridi
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Cultivate peace and relaxation as you tend to your virtual succulent garden in Viridi.
				</Text>
				<Button
					font="normal 400 16px/1.5 --fontFamily-sans"
					background="--color-dark"
					padding="12px 28px 12px 28px"
					type="link"
					href="https://play.google.com/store/apps/details?id=com.IceWaterGames.Viridi&hl=en"
					text-decoration-line="initial"
				>
					Download
				</Button>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" id="about">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Our mission is to bring serenity to your life.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Viridi is designed to provide a peaceful, nurturing experience where you care for a tiny succulent garden. With gentle reminders to water and tend to your plants, it's a calming escape from the hustle and bustle.
				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-3.jpg?v=2024-11-05T13:39:59.930Z"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="1 / span 1"
					border-radius="24px"
					srcSet="https://smartuploads.quarkly.io/672a14af9710450018a414e9/images/game-3.jpg?v=2024-11-05T13%3A39%3A59.930Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/672a14af9710450018a414e9/images/game-3.jpg?v=2024-11-05T13%3A39%3A59.930Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/672a14af9710450018a414e9/images/game-3.jpg?v=2024-11-05T13%3A39%3A59.930Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/672a14af9710450018a414e9/images/game-3.jpg?v=2024-11-05T13%3A39%3A59.930Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/672a14af9710450018a414e9/images/game-3.jpg?v=2024-11-05T13%3A39%3A59.930Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/672a14af9710450018a414e9/images/game-3.jpg?v=2024-11-05T13%3A39%3A59.930Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/672a14af9710450018a414e9/images/game-3.jpg?v=2024-11-05T13%3A39%3A59.930Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-1.jpg?v=2024-11-05T13:39:59.905Z"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="2 / span 2"
					border-radius="24px"
					sm-grid-column="1 / span 1"
					srcSet="https://smartuploads.quarkly.io/672a14af9710450018a414e9/images/game-1.jpg?v=2024-11-05T13%3A39%3A59.905Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/672a14af9710450018a414e9/images/game-1.jpg?v=2024-11-05T13%3A39%3A59.905Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/672a14af9710450018a414e9/images/game-1.jpg?v=2024-11-05T13%3A39%3A59.905Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/672a14af9710450018a414e9/images/game-1.jpg?v=2024-11-05T13%3A39%3A59.905Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/672a14af9710450018a414e9/images/game-1.jpg?v=2024-11-05T13%3A39%3A59.905Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/672a14af9710450018a414e9/images/game-1.jpg?v=2024-11-05T13%3A39%3A59.905Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/672a14af9710450018a414e9/images/game-1.jpg?v=2024-11-05T13%3A39%3A59.905Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="130px 0 140px 0"
			background="linear-gradient(0deg,rgba(116, 12, 54, 0.5) 0%,rgba(116, 12, 54, 0.56) 100%),#111828 url(https://uploads.quarkly.io/672a14af9710450018a414e9/images/game-4.jpg?v=2024-11-05T13:39:59.904Z) 50%/cover"
			color="#000000"
			sm-padding="80px 0 90px 0"
			md-padding="80px 0 80px 0"
			quarkly-title="Statistics-8"
			border-color="rgba(0, 0, 0, 0.75)"
			id="features"
		>
			<Box min-width="100px" min-height="100px" padding="0px 600px 0px 0px" lg-padding="0px 0 0px 0px">
				<Text margin="0px 0px 10px 0px" color="--light">
					Our Purpose
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="normal 600 42px/1.2 --fontFamily-sans" sm-font="normal 600 36px/1.2 --fontFamily-sans">
					Join us in creating a tranquil space for everyone to unwind.
				</Text>
				<Text margin="25px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--light">
					Viridi offers a sanctuary of calmness, where each player can cultivate a miniature oasis to find relaxation in life’s little moments.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				margin="100px 0px 0px 0px"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-rows="auto"
				md-margin="50px 0px 0px 0px"
				lg-margin="70px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(77, 66, 162, 0.5)"
					padding="0px 0px 0px 25px"
					md-margin="0px 0px 25px 0px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 500 42px/1.2 --fontFamily-sans" sm-font="normal 700 30px/1.2 --fontFamily-sans">
						100+
					</Text>
					<Text margin="15px 0px 0px 0px" color="--lightD1" font="normal 300 17px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" sm-margin="8px 0px 0px 0px">
						Unique succulents to grow
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(77, 66, 162, 0.5)"
					padding="0px 0px 0px 25px"
					md-margin="0px 0px 25px 0px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 500 42px/1.2 --fontFamily-sans" sm-font="normal 700 30px/1.2 --fontFamily-sans">
						5+
					</Text>
					<Text margin="15px 0px 0px 0px" color="--lightD1" font="normal 300 17px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" sm-margin="8px 0px 0px 0px">
						Years of growth in real-time
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(77, 66, 162, 0.5)"
					padding="0px 0px 0px 25px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 500 42px/1.2 --fontFamily-sans" sm-font="normal 700 30px/1.2 --fontFamily-sans">
						24/7
					</Text>
					<Text margin="15px 0px 0px 0px" color="--lightD1" font="normal 300 17px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" sm-margin="8px 0px 0px 0px">
						Relaxing gameplay
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(77, 66, 162, 0.5)"
					padding="0px 0px 0px 25px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 500 42px/1.2 --fontFamily-sans" sm-font="normal 700 30px/1.2 --fontFamily-sans">
						10K+
					</Text>
					<Text margin="15px 0px 0px 0px" color="--lightD1" font="normal 300 17px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" sm-margin="8px 0px 0px 0px">
						Players worldwide
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-lightD1" quarkly-title="FAQ-10" id="faq">
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				lg-flex-direction="column"
				width="100%"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						FAQ
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
					width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							What type of plants can I grow in Viridi?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Viridi lets you nurture a variety of succulents, from classic favorites to unique, rare species.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Is there any pressure or goals in the game?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							No, Viridi is designed for relaxation with no strict goals or deadlines, allowing you to play at your own pace.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							How often should I water my plants in Viridi?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Viridi reminds you to water your plants regularly, mimicking the natural growth of succulents with no stress.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Can I customize my garden?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Yes, you can arrange and grow a diverse range of plants to create a garden that suits your style and preference.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="150px 0 150px 0" md-padding="80px 0 80px 0" sm-padding="50px 0 50px 0" quarkly-title="CTA/Lead/Form-3">
			<Box
				min-width="100px"
				min-height="100px"
				padding="80px 80px 80px 80px"
				background="rgba(0, 0, 0, 0) radial-gradient(circle at center,#f600f3 0%,--color-secondary 100%) 0% 0% /cover repeat scroll padding-box"
				border-radius="40px"
				md-padding="50px 50px 50px 50px"
				sm-padding="50px 30px 50px 30px"
			>
				<Text
					margin="0px 180px 20px 180px"
					color="--light"
					text-align="center"
					font="normal 700 42px/1.2 --fontFamily-sans"
					lg-margin="0px 0 20px 0"
					sm-font="normal 700 36px/1.2 --fontFamily-sans"
					sm-text-align="left"
				>
					Get in Touch with Us to Learn More About Viridi
				</Text>
				<Text
					margin="0px 200px 50px 200px"
					text-align="center"
					font="--lead"
					color="rgba(255, 255, 255, 0.8)"
					lg-margin="0px 0 50px 0"
					sm-text-align="left"
				>
					Our team is here to answer any questions you have and help you on your journey to creating a serene succulent garden.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					justify-content="center"
					sm-flex-direction="column"
					sm-align-items="center"
				>
					<Button background="rgba(247, 251, 255, 0)" font="normal 400 17px/1.5 --fontFamily-sans" type="link" href="https://play.google.com/store/apps/details?id=com.IceWaterGames.Viridi&hl=en">
						Learn more &gt;&gt;
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer2>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text3" />
		</Components.Footer2>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66fbc4301b94b10024315de0"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});